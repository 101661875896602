import { ByteUnit } from "../functions/byte-unit";

export const DefaultImageUploadMaxFileSize = ByteUnit.convert(
  10,
  ByteUnit.MB,
  ByteUnit.Byte,
);

export const LinkedInImageUploadMaxFileSize = ByteUnit.convert(
  8,
  ByteUnit.MB,
  ByteUnit.Byte,
);

export const LinkedInVideoUploadMaxFileSize = ByteUnit.convert(
  200,
  ByteUnit.MB,
  ByteUnit.Byte,
);
